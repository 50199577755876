// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-archer-rx-js": () => import("./../../../src/pages/Archer-RX.js" /* webpackChunkName: "component---src-pages-archer-rx-js" */),
  "component---src-pages-article-covid-19-testing-for-employees-js": () => import("./../../../src/pages/article-covid-19-testing-for-employees.js" /* webpackChunkName: "component---src-pages-article-covid-19-testing-for-employees-js" */),
  "component---src-pages-article-everyone-benefits-from-employee-wellness-programs-js": () => import("./../../../src/pages/article-everyone-benefits-from-employee-wellness-programs.js" /* webpackChunkName: "component---src-pages-article-everyone-benefits-from-employee-wellness-programs-js" */),
  "component---src-pages-article-the-importance-of-biometric-screenings-for-employee-health-js": () => import("./../../../src/pages/article-the-importance-of-biometric-screenings-for-employee-health.js" /* webpackChunkName: "component---src-pages-article-the-importance-of-biometric-screenings-for-employee-health-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-us-form-js": () => import("./../../../src/pages/contact-us-form.js" /* webpackChunkName: "component---src-pages-contact-us-form-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-contact-us-success-js": () => import("./../../../src/pages/contact-us-success.js" /* webpackChunkName: "component---src-pages-contact-us-success-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-onsite-clinics-js": () => import("./../../../src/pages/onsite-clinics.js" /* webpackChunkName: "component---src-pages-onsite-clinics-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-virtual-care-js": () => import("./../../../src/pages/virtual-care.js" /* webpackChunkName: "component---src-pages-virtual-care-js" */),
  "component---src-pages-why-archerhealth-js": () => import("./../../../src/pages/why-archerhealth.js" /* webpackChunkName: "component---src-pages-why-archerhealth-js" */)
}

